<template>
    <div class="revenue-content">
        <div class="charge-record"
             v-for="item in renderData"
             v-if="!!renderData && item.recordList.length">
            <div class="summary">
                <p class="title">{{item.name}}</p>
                <!-- <p class="total">￥{{item.recordList}}</p> -->
                <p class="total">￥{{receiptTotal(item.recordList)}}</p>
            </div>
           <charge-record-list :recordList="item.recordList" :timeType="timeParams.type"></charge-record-list>
        </div>
    </div>
</template>

<script>
	import table from '@/api/table'
	import {mapState} from 'vuex'

	export default {
		name:"revenue",
		props:{
			timeParams:Object
		},
		data(){
			return {
				chargeRecord:null,
				renderData:null
			}
		},
		computed:{
			...mapState(['userStatus']),
			receiptTotal(){
				return function(recordList){
					let receiptTotal = 0
					for(let item of recordList){
						receiptTotal += parseFloat(item.time_data)
					}
					if(! receiptTotal || receiptTotal == 0){
						receiptTotal = "0.00"
					}else{
						receiptTotal = parseFloat(receiptTotal).toLocaleString('en-US')
					}
					return receiptTotal
				}
			},
		},
		watch:{
			timeParams:{
				handler(newVal,oldVal){
					let {timeParams} = this

					if(! timeParams){
						return
					}
					if(! newVal){
						return
					}
					if(! ! oldVal && newVal.startTime === oldVal.startTime){
						return
					}
					this.loadData()
				},
				deep:true,
				immediate:true,
			},
			'userStatus.curs_node_id_2':{
				handler(){
					let {timeParams} = this

					if(! timeParams){
						return
					}
					this.loadData()
				},
				deep:true,
				immediate:true,
			}
		},
		components:{
			'charge-record-list':() => import('./charge-record-list')
		},
		methods:{
			loadData(){
				this.getChargeRecord().then(() =>{
					this.getRenderData()
				})
			},
			/**
			 * 获取收费记录
			 */
			getChargeRecord(){
				let {shopNo,openid,timeParams} = this
				let {type,startTime,endTime} = timeParams
				let params = {
					shopNo,
					openid,
					// timeType:type,
					startTime,
					endTime,
				}
				return new Promise((resolve,reject) =>{
					table.getChargeRecord(params).then(res =>{
						this.chargeRecord = res.data
						resolve()
					}).catch(err =>{
						console.log("err",err.info)
						reject()
					})
				})
			},
			/**
			 * 获取渲染数据
			 */
			getRenderData(){
				let {chargeRecord} = this
				let renderData = [
					{
						name:'各现金收款',
						recordList:chargeRecord.cash_receipt
					},
					{
						name:'其他消费收款',
						recordList:chargeRecord.other_receipt
					},
				]
				this.renderData = renderData
			}
		},
	}
</script>

<style lang="scss" type="text/scss" scoped>
    @import "~@/assets/style/public.scss";

    .charge-record{
        background:#FFFFFF;
        margin:18px 0 0 0;
        padding:0 30px 20px;
    }

    .summary{
        padding:40px 0 0;
        line-height:50px;
    }

    .title{
        font-size:32px;
        text-align:center;
        color:rgb(51, 51, 51);
        font-weight:bold;
        @extend %flex-center-row;
    }

    .title:after,
    .title:before{
        content:'';
        width:100px;
        height:3px;
        background:rgb(238, 238, 238);
        margin:0 20px;
    }

    .total{
        font-size:32px;
        color:rgb(51, 51, 51);
        font-weight:bold;
        text-align:center;
    }

</style>
